/* div {
  border: 1px dashed #e8e8e8;
} */

body,
html {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 20px;
}

.centered {
  text-align: center;
}


#Header {
  padding: 25px 0;
}

.ApproachCells {
  text-align: left;
  margin: 0;
  padding: 0 0 30px 0;
  border: none;
}

.BrandCells {
  text-align: center;
  margin: 10px;
  padding: 0;
  position: relative;
  overflow: hidden;
  background: white;
  transition: transform 0.3s ease;
}

.BrandCells:hover {
  transform: translateY(-5px);
}

.portfolio-card {
  position: relative;
  height: 520px;
  cursor: pointer;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 25px 15px;
  text-align: center;
}

.portfolio-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px 0;
  color: #000;
  text-transform: uppercase;
}

.portfolio-subtitle {
  font-size: 13px;
  color: #999;
  margin: 0;
  text-transform: uppercase;
}

.TrustCells {
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

h1 {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
}

.SectionSubtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.1em;
  margin-bottom: -15px;
}

h2 {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 60px;
}

.Section {
  padding: 150px 0;
}

.SectionLight {
  background-color: rgba(0, 0, 0, 0.1);
}

.SectionDark {
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.9);
}

#Footer {
  background-color: #000000;
  color: #fff;
}

.DividerLight {
  border-color: rgba(255, 255, 255, 0.1);
}

.approach-number {
  font-size: 72px;
  font-weight: 700;
  color: rgba(0,0,0,0.5);
  margin: 0;
  line-height: 1;
}

.approach-title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.approach-subtitle {
  margin: 0;
  font-size: 24px;
  color: #FF5722;
}

.approach-text {
  color: rgba(0,0,0,0.6);
  margin-top: 20px;
  line-height: 1.6;
  font-size: 15px;
  max-width: 90%;
}

.logo-wrapper {
    margin: 75px 0;
}

.trust-logo {
    width: 100%;
    filter: grayscale(90%);
    opacity: 0.7;
    transition: all 0.3s ease;
}

.trust-logo:hover {
    filter: grayscale(90%);
    opacity: 1;
}

#HeroContainer {
    position: relative;
    min-height: max(500px, 50vh);
    background: url('./images/hero.jpg') center center/cover no-repeat;
    color: white;
}

#HeroContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

#HeroContainer > * {
    position: relative;
    z-index: 2;
}

#Header {
    position: relative;
}

#Header a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
}

.contact-button {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    padding: 8px 24px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 500;
    transition: background 0.3s ease;
    font-size: 16px;
}

.contact-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.hero-content {
    padding-bottom: 180px;
    text-align: left;
}

.hero-content h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 40px;
    color: white;
}

.discover-button {
    background: #FF5722;
    color: white;
    border: none;
    padding: 16px 40px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s ease;
}

.discover-button:hover {
    background: #F4511E;
}

.brand-card {
    position: relative;
    height: 400px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    overflow: hidden;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.brand-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
}

.brand-card:hover::before {
    background: linear-gradient(to top, #FF5722, rgba(0, 0, 0, 0.4));
}

.brand-card > * {
    position: relative;
    z-index: 2;
}

.brand-icon {
    background: rgba(255, 255, 255, 0.1);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.brand-card h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 1rem 0;
    color: white;
}

.brand-card p {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
}

.brand-card:hover p {
    opacity: 1;
    transform: translateY(0);
}

.footer-contact {
    font-size: 14px;
    opacity: 0.7;
    line-height: 1.6;
}

.footer-contact p {
    margin-bottom: 20px;
}

.footer-contact a {
    color: #FF5722;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.footer-contact a:hover {
    opacity: 0.8;
}

/* Logo text */
.logo h1 {
    font-family: 'Jost', sans-serif;
    font-weight: 900;
    font-style: italic;
    font-size: 55px;
    margin:0;
}

/* All other h1 elements */
h1:not(.logo h1) {
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
}

/* Update button text */
.discover-button,
.contact-button {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
}

/* All other text elements will inherit from body */
p, a, span, div {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
}

/* Update footer link styles */
#Footer a {
    color: #FF5722;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

#Footer a:hover {
    opacity: 0.8;
}

/* Update footer contact info links specifically */
.footer-contact a {
    color: #FF5722;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.footer-contact a:hover {
    opacity: 0.8;
}

/* Additional breakpoints for more control */
@media screen and (max-width: 768px) {
    .hero-content {
        padding: 0;
    }
    
    .hero-content h1 {
        font-size: clamp(38px, 6vw, 64px);
    }
}

/* Mobile menu styles */
.desktop-menu {
    display: block;
}

.mobile-menu-trigger {
    display: none;
}

.hamburger-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    height: 100%;
}

.mobile-menu-item {
    background: none;
    border: none;
    color: white;
    font-family: 'Cabin', sans-serif;
    font-weight: 400;
    font-size: 28px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
    transition: color 0.3s ease;
    width: 100%;
}

.mobile-menu-item:hover {
    color: #FF5722;
}

.mobile-menu-item.highlight {
    color: #FF5722;
}

.mobile-menu-item.highlight:hover {
    opacity: 0.8;
}

/* Override Ant Design drawer styles */
.mobile-menu-drawer .ant-drawer-content-wrapper {
    background: #000000;
}

.mobile-menu-drawer .ant-drawer-close {
    color: white;
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    .desktop-menu {
        display: none;
    }

    .mobile-menu-trigger {
        display: block;
    }
}

/* Update mobile menu styles */
.mobile-menu-drawer .ant-drawer-header {
    border-bottom: none;
    padding: 16px;
}

.mobile-menu-drawer .ant-drawer-close {
    margin: 0;
}

.mobile-menu-item {
    background: none;
    border: none;
    color: white;
    font-family: 'Cabin', sans-serif;
    font-weight: 400;
    font-size: 28px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
    transition: color 0.3s ease;
    width: 100%;
}

.mobile-menu-item:hover {
    color: #FF5722;
}

.mobile-menu-item.highlight {
    color: #FF5722;
}

.mobile-menu-item.highlight:hover {
    opacity: 0.8;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
    h2 {
        font-size: clamp(28px, 5vw, 36px);
        margin-bottom: 40px;
    }

    .approach-number {
        font-size: clamp(48px, 8vw, 72px);
    }

    .approach-title {
        font-size: clamp(20px, 4vw, 24px);
    }

    .approach-subtitle {
        font-size: clamp(20px, 4vw, 24px);
    }

    .approach-text {
        font-size: 14px;
        max-width: 100%;
    }

    .ApproachCells {
        padding: 0 0 20px 0;
    }
}

/* Tablet specific adjustments */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .Section {
        padding: clamp(80px, 10vh, 100px) 0;
    }

    .approach-text {
        max-width: 95%;
    }
}